import * as React from "react"
import { graphql } from "gatsby"

// Components
import Layout from "../components/layout"
import ShortPost from "../components/blog-post/shortPost"
import SEO from "../components/seo"

class BlogPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filters: []
    }
    this.filter = this.filter.bind(this)
  }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tag = urlParams.get('tag')
    if (tag) this.setState({ filters: [tag] })
  }

  filter(tag) {
    let tags = [...this.state.filters];
    if (tags.includes(tag)) tags = tags.filter(t => t !== tag);
    else tags.push(tag)
    this.setState({ filters: [...tags] });
  }

  render({ data }) {
    return (
      <Layout classes="blog">
        <SEO
          title="Mon blog"
          description="Sur ce blog, vous trouverez des articles pour découvrir des framework front-end à travers le développement de petits projets ou la résolution de bugs."
          type="blog"
        />
        <div className="blog__content container">
          <h1 className="mf-title-underscore"><span>Blog</span></h1>
          <div className="mt-4">
            <span className="mr-3">Filtrer :</span>
            {data.allWpCategory.nodes.map(node => (
              <button
                onClick={() => this.filter(node.slug)}
                className={`mf-tag btn btn-sm badge badge-pill mr-2 ${this.state.filters.includes(node.slug) ? 'btn-primary' : 'btn-outline-primary'}`}
              >{node.name}</button>
            ))}

          </div>
          <div>
            {data.allWpPost.nodes
              .filter(n => this.state.filters.length === 0 || this.state.filters.includes(n.categories.nodes[0]?.slug))
              .map(node => (
                <div key={node.slug}>
                  <ShortPost data={node} imageSize="large" noClickTag />
                </div>
              ))}
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPage

export const pageQuery = graphql`
  query {
    allWpCategory {
      nodes {
        slug
        name
      }
    }

    allWpPost(sort: { fields: [date], order: [DESC] }) {
      nodes {
        title
        excerpt
        date
        slug
        categories{
          nodes{
            name
            slug
          }
        }
        details {
          gradient {
            startcolor
            endcolor
          }
          banner {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`